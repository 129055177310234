import React from "react"
import Banner from "../components/Banner/banner"

const HomePage = () => {
  return (
    <>
      <Banner />
    </>
  )
}

export default HomePage
