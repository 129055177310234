import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { motion } from "framer-motion"
import { BannerStyles } from "../../styles/bannerStyles"
import { Button } from "react-bootstrap"
import { FaTwitter } from "react-icons/fa"

const Banner = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "clouds6.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, width: 3840, formats: [AUTO, WEBP])
          }
        }
      }
    `
  )

  const image = getImage(placeholderImage)

  const bgImage = convertToBgImage(image)

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  }

  return (
    <BannerStyles>
      <BackgroundImage
        Tag="section"
        {...bgImage}
        preserveStackingContext
        className="hero-image"
      >
        <GatsbyImage image={image} />
        <div className="hero-content">
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1 }}
          >
            A lending protocol built by DeFi users for DeFi users
          </motion.h1>
          <motion.p
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1.5 }}
          >
            Jet is a lending protocol built on the Solana blockchain with a
            focus on innovative lending products and cross-chain interest rate
            arbitrage.
          </motion.p>
          <div>
            <a
              href="https://medium.com/jetprotocol"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button variant="primary" className="mr-2">
                Announcement
              </Button>
            </a>
            <a
              href="https://jetprotocol.substack.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button variant="outline-secondary" className="mr-2">
                Newsletter
              </Button>
            </a>
            <a
              className="p-3"
              href="https://twitter.com/jetprotocol"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
          </div>
        </div>
      </BackgroundImage>
    </BannerStyles>
  )
}

export default Banner
